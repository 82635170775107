import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { NavigationCancel, NavigationEnd, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { LayoutService } from "../../core/layout.service";
import { MenuComponent } from "../../../kt/components";
import * as crypto from 'crypto-js';
import { HttpClient } from "@angular/common/http";
import { AngularFirestore } from "@angular/fire/compat/firestore";
@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit, OnDestroy {
  headerContainerCssClasses: string = "";
  showHeaderMenu: boolean = false;
  @ViewChild("ktPageTitle", { static: true }) ktPageTitle: ElementRef;

  private unsubscribe: Subscription[] = [];

  constructor(private layout: LayoutService, private http: HttpClient, private router: Router, private db: AngularFirestore) {
    this.routingChanges();
    const headerMenu = this.layout.getProp("header.menu") as
      | boolean
      | undefined;
    if (headerMenu) {
      this.showHeaderMenu = false;
    }
  }
  randomString(length) {
    var randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var result = '';
    for (var i = 0; i < length; i++) {
      result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
    }
    return result;
  }
  ngOnInit(): void {
    const key = "J16SDyGk7XR1dZt3mwhu";
    let agentdata = localStorage.getItem("nabngientoken")
    let sha = crypto.SHA256(key).toString();
    let md5 = crypto.MD5(sha).toString();
    let decData = crypto.enc.Base64.parse(agentdata).toString(
      crypto.enc.Utf8
    );
    let bytesa = crypto.AES.decrypt(decData || "", md5).toString(
      crypto.enc.Utf8
    );
    const jsonobj = JSON.parse(bytesa);
    // this.appRef.detectChanges();
    this.db
      .collection("members")
      .doc("memberinfo")
      .collection("data")
      .doc(jsonobj[0].id)
      .get()
      .subscribe((res: any) => {
        if (res.data().ingame === true) {
          let iduser = localStorage.getItem("id");
          var sha = crypto.SHA256("J16SDyGk7XR1dZt3mwhu").toString();
          var md5 = crypto.MD5(sha).toString();
          let encJson = crypto.AES.encrypt(
            JSON.stringify({
              iduser: iduser,
              key: this.randomString(20)
            }),
            md5
          ).toString();
          let encData = crypto.enc.Base64.stringify(
            crypto.enc.Utf8.parse(encJson)
          );

          this.http
            .post(
              "https://asia-southeast1-royal-a9389.cloudfunctions.net/api/amb/slotLogOutPGSlot",
              { token: encData }
            )
            .subscribe((res: any) => {

              // console.log(res);
            });
        }
      });
    this.headerContainerCssClasses =
      this.layout.getStringCSSClasses("headerContainer");
  }

  routingChanges() {
    const routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd || event instanceof NavigationCancel) {
        MenuComponent.reinitialization();
      }
    });
    this.unsubscribe.push(routerSubscription);
  }

  ngOnDestroy() { }
}
