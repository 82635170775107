<!-- <app-theme-mode-switcher
  class="d-flex align-items-center"
  [ngClass]="itemClass"
  toggleBtnClass="{`btn-active-light-primary btn-custom ${btnClass}`}"
>
</app-theme-mode-switcher> -->
<!-- end::Theme mode -->

<!--begin::User-->
<div class="d-flex align-items-center ms-lg-5" id="kt_header_user_menu_toggle">
  <!--begin::User info-->
  <div class="btn d-flex align-items-center py-2 px-2 px-md-3">
    <!--begin::Name-->
    <div class="d-none d-md-flex flex-column align-items-end justify-content-center me-2"></div>
    <div class="symbol symbol-30px symbol-md-40px mr-3">
      <a href="https://lin.ee/W8RwWd4">
        <img _ngcontent-oym-c72="" src="/assets/media/logos/line-icon.png" width="35" alt="">
      </a>
    </div>

    <div class="symbol symbol-30px symbol-md-40px" routerLink="/member/info">
      <img src="/assets/images/avatar-icon.png" alt="image" />
    </div>
    <!--end::Symbol-->
  </div>
  <!--end::User info-->

  <!-- <app-user-inner></app-user-inner> -->
</div>
<!--end::User -->