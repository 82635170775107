<!--begin::Container-->

<div [ngClass]="headerContainerCssClasses"
  class="d-flex align-items-stretch justify-content-between z-50 border-b border-F6A32F bg-black"
  id="kt_header_container">
  <!--begin::Brand-->
  <div class="d-flex align-items-center flex-grow-1 flex-lg-grow-0 w-lg-225px me-5">
    <!--begin::Aside mobile toggle-->
    <!-- <div class="btn btn-icon btn-active-icon-primary ms-n2 me-2 d-flex d-lg-none" id="kt_aside_toggle">
      <span [inlineSVG]="'./assets/media/icons/duotune/abstract/abs015.svg'" class="svg-icon svg-icon-1"></span>
    </div> -->
    <!--end::Aside mobile toggle-->

    <!--begin::Logo-->
    <a>

      <!-- <img alt="Logo" src="./assets/media/logos/logo.png" class="h-30px" /> -->
    </a>
    <!--end::Logo-->
  </div>
  <!--end::Brand-->

  <!-- begin::Wrapper -->
  <div class="d-flex align-items-stretch justify-content-end flex-lg-grow-1">
    <!--begin::Navbar-->
    <!-- <div class="d-flex align-items-stretch" id="kt_header_nav">
      <ng-container *ngIf="showHeaderMenu">
        <app-header-menu
          class="header-menu align-items-stretch"
          data-kt-drawer="true"
          data-kt-drawer-name="header-menu"
          data-kt-drawer-activate="{default: true, lg: false}"
          data-kt-drawer-overlay="true"
          data-kt-drawer-width="{default:'200px', '300px': '250px'}"
          data-kt-drawer-direction="end"
          data-kt-drawer-toggle="#kt_header_menu_mobile_toggle"
          data-kt-swapper="true"
          data-kt-swapper-mode="prepend"
          data-kt-swapper-parent="{default: '#kt_body', lg: '#kt_header_nav'}"
        ></app-header-menu>
      </ng-container>
    </div> -->
    <!--end::Navbar-->

    <app-topbar class="d-flex align-items-stretch flex-shrink-0"></app-topbar>
  </div>
  <!-- end::Wrapper -->
</div>
<!--end::Container-->