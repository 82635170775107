import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { AuthService } from './auth.service';
import * as crypto from 'crypto-js';
@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // const currentUser = this.authService.currentUserValue;
    // if (currentUser) {
    //   return true;
    // }
    // this.authService.logout();
    // return false;
    try {
      const key = "J16SDyGk7XR1dZt3mwhu";
      let agentdata = localStorage.getItem("nabngientoken")
      let sha = crypto.SHA256(key).toString();
      let md5 = crypto.MD5(sha).toString();
      let decData = crypto.enc.Base64.parse(agentdata).toString(
        crypto.enc.Utf8
      );
      let bytesa = crypto.AES.decrypt(decData || "", md5).toString(
        crypto.enc.Utf8
      );
      var decryptedData = JSON.parse(bytesa);
      if (agentdata !== null && decryptedData[0].nabngien === true) {
        // logged in so return true
        return true;
      }
      else {
        let homepage = localStorage.getItem('homepage');
        if (homepage == null) {
          this.router.navigate(['/home'])
          return false;

        }
        else {
          this.authService.logout();
          return false;
        }

      }
    }
    catch {
      let homepage = localStorage.getItem('homepage');
      if (homepage == null) {
        this.router.navigate(['/home'])
        return false;

      }
      else {
        this.authService.logout();
        return false;
      }
    }
  }
}
