import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from "@angular/core";
import { TranslationService } from "./modules/i18n";
// language list
import { locale as enLang } from "./modules/i18n/vocabs/en";
import { locale as chLang } from "./modules/i18n/vocabs/ch";
import { locale as esLang } from "./modules/i18n/vocabs/es";
import { locale as jpLang } from "./modules/i18n/vocabs/jp";
import { locale as deLang } from "./modules/i18n/vocabs/de";
import { locale as frLang } from "./modules/i18n/vocabs/fr";
import { ThemeModeService } from "./_metronic/partials/layout/theme-mode-switcher/theme-mode.service";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { SplashScreenService } from './_metronic/partials/layout/splash-screen/splash-screen.service';
import { NavigationEnd, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { UpdateService } from './_metronic/layout/core/update.service';
@Component({
  // tslint:disable-next-line:component-selector
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "body[root]",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy {
  private unsubscribe: Subscription[] = [];
  constructor(
    private translationService: TranslationService,
    private modeService: ThemeModeService,
    private router: Router,
    private db: AngularFirestore,
    private update: UpdateService,
    private splashScreenService: SplashScreenService,
  ) {
    // register translations
    this.translationService.loadTranslations(
      enLang,
      chLang,
      esLang,
      jpLang,
      deLang,
      frLang
    );
  }

  ngOnInit() {
    this.modeService.init();
    this.update.init();
    const routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {

        setTimeout(() => {
          // hide splash screen
          this.splashScreenService.hide();

          // scroll to top on every route change
          window.scrollTo(0, 0);

          // to display back the body content
          setTimeout(() => {
            document.body.classList.add('page-loaded');
          }, 800);
        }, 800);
      }
    });
    this.unsubscribe.push(routerSubscription);
    if (localStorage.getItem("logopath") === null) {
      this.db.collection("logoweb").doc("data").get().subscribe((dat: any) => {
        localStorage.setItem("logopath", dat.data().src)
      })
    }

  }
  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
}
